@charset 'UTF-8';
@import "/Users/VuongHuy/Website/vhost/furnituredome/src/scss/utility/_burger.scss";
@import "/Users/VuongHuy/Website/vhost/furnituredome/src/scss/utility/_flex.scss";
@import "/Users/VuongHuy/Website/vhost/furnituredome/src/scss/utility/_media-queries.scss";
@import "/Users/VuongHuy/Website/vhost/furnituredome/src/scss/utility/_mixin.scss";

#page404 {
  .page-title {
    @include ffRoboto;
    @include font(100,100,100,700);
    text-align: center;
    text-transform: uppercase;
    @include SP {
      @include font(50,50)
    }
  }
  .text {
    text-align: center;
    p {
      padding: 30px 0 60px;
      @include font(18,35);
      @include SP {
        @include font(16,30)
      }
    }
  }
}

.page_404 {
    padding: 200px 0;
    @include SP {
      padding: 100px 0;
    }
}